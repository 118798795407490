import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HelpersService } from '../../shared/services/helpers.service';
import { SetsService } from '../../shared/services/sets.service';
import { datadogRum } from '@datadog/browser-rum';
import { RtcService } from 'src/app/shared/services/rtc.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  smallChild = false;
  mediumChild = false;
  largeChild = false;
  userDetails: any;
  userDetailsAvailable = false;
  userDetailsBearer = new Subject();
  currentSet;
  private url: string;
  version = '1.0.15';
  private profileSub: Subscription;
  currentSetStats: any;

  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private helpers: HelpersService,
    private setsService: SetsService
  ) {
    this.url = platform.url;
    this.profileSub = this.authService.profile().subscribe((user) => {
      this.storeUserDetails(user);
      datadogRum.setUser({
        name: user.name,
      });
      if (this.platform.env !== 'dev') {
        datadogRum.init({
          applicationId: '200e3c6b-26ff-4b39-9bbe-c9d2f308daaf',
          clientToken: 'pub00436916c5ed35c4e66876f11b0bc130',
          site: 'datadoghq.eu',
          service: 'ionic',
          env: '<ENV_NAME>',
          // Specify a version number to identify the deployed version of your application in Datadog
          version: this.version,

          sessionSampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
        datadogRum.startSessionReplayRecording();
      }
    });

    // this.setsService.setContent.asObservable().subscribe((setContent: any) => {
    //   let setToReplace = this.userDetails.userData.sets.find(
    //     (set) => set.parentSet === setContent.parentSet
    //   );
    //   if (setToReplace) {
    //     setToReplace = setContent;
    //     this.getUserDetails();
    //   }
    // });
  }
  resetProgress() {
    const numberMilisecondsFromLastReset =
      Date.now() - this.userDetails.userData.lastResetProgress;

    // if (numberMilisecondsFromLastReset > 604800000) {
    this.userDetails.userData.lastWeekProgress = this.helpers.clone(
      this.userDetails.userData.weeklyProgress
    );
    this.userDetails.userData.weeklyProgress = 0;
    this.userDetails.userData.haventLearnedThisWeek = true;
    this.http
      .put(
        this.url + '/api/users/reset-weekly-progress/' + this.userDetails._id,
        {
          headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 116 ~ UsersServiceService ~ resetWeeklyProgress ~ response',
          response
        );
      }),
      (error) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 119 ~ UsersServiceService ~ resetWeeklyProgress ~ error',
          error
        );
      };
    // window.location.reload();
    // }
    //  else {
    //   this.userDetails.userData.lastWeekProgress =
    //     this.userDetails.userData.progressHistory[0]?.progress;
    // }
  }
  addusersCollectableItems(userId, collectableItem) {
    const url = this.url + '/api/users/adduserscollectableitems/' + userId;
    console.log(url);
    this.http
      .put(
        this.url + '/api/users/adduserscollectableitems/' + userId,
        { collectableItem: collectableItem },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => { });
  }
  storeUserDetails(userDetails) {
    this.userDetails = userDetails;
    this.getUsersSets(userDetails._id ? userDetails._id : userDetails.id);

    this.userDetailsBearer.next(this.userDetails);
  }
  clearUserDetails() {
    this.userDetails = null;
    this.userDetailsAvailable = false;
    this.userDetailsBearer.next(null);
    this.currentSet = null;
  }
  getUserDetails() {
    if (this.userDetails) {
      this.userDetailsBearer.next(this.userDetails);
      return this.userDetails;
    }
    // if (!this.userDetails) {
    //   this.profileSub = this.authService.profile().subscribe((user) => {
    //     this.storeUserDetails(user);
    //     this.userDetailsBearer.next(this.userDetails);
    //   });
    // }
  }

  checkAgeStatus() {
    const ud: any = this.getUserDetails();
    if (ud) {
      switch (ud.userData?.ageStatus) {
        case 'smallChild':
          this.smallChild = true;
          break;
        case 'mediumChild':
          this.mediumChild = true;
          break;
        case 'largeChild':
          this.largeChild = true;
          break;

        default:
          break;
      }

      return ud.userData?.ageStatus;
    } else {
      return null;
    }
  }

  getSimpleUserDetails() {
    const ud: any = this.getUserDetails();
    if (ud) {
      return {
        id: ud.id ? ud.id : ud._id,
        name: ud.name,
        role: ud.role,
        avatar: ud.avatar ? ud.avatar : null,
      };
    } else {
      return null;
    }
  }

  storeCurrentSet(set) {
    this.currentSet = set;
    // let currentSetInUserSets = this.userDetails.userData.sets.find(
    //   (_set) => _set?.parentSet === set.parentSet || _set?.id === set.id
    // );
    // if (currentSetInUserSets) {
    //   currentSetInUserSets = set;
    // } else {
    //   this.userDetails.userData.sets.push(set);
    // }
    // this.userDetailsBearer.next(this.userDetails);
  }
  storeCurrentSetsStats(stats) {
    // this.currentSetStats = stats;
    // this.userDetailsBearer.next(this.userDetails);
  }
  getCurrentSetsStats() {
    return this.currentSetStats;
  }

  getCurrentSet() {
    return this.currentSet;
  }

  getUsersSets(id) {
    //   this.http
    //     .post(
    //       this.url + '/api/users/getuserssetswithgroupssets',
    //       { studentId: id },
    //       {
    //         headers: { Authorization: `Bearer ${this.authService.getToken()}` },
    //       }
    //     )
    //     .subscribe((response: any) => {
    //       response.sets.forEach((set) => {
    //         if (set?.stats?.quizParams?.lastLearned) {
    //           set.lastLearned = set?.stats?.quizParams?.lastLearned;
    //         } else {
    //           set.lastLearned = 0;
    //         }
    //       });
    //       this.userDetails.userData.sets = this.helpers.sortArrayByProperty(
    //         response.sets,
    //         'added',
    //         -1
    //       );

    //       this.userDetails.userData.newSets =
    //         this.userDetails.userData.sets.filter((set) => set.new);
    //       this.userDetails.userData.sets = this.userDetails.userData.sets.filter(
    //         (set) => !set.new
    //       );
    //       this.getUserDetails();
    //     });
  }
}
