import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './home/progress/profile.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    loadChildren: () =>
      import('./goto/goto.module').then((m) => m.GotoPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.ProfileWrapperPageModule),
  },
  {
    path: 'prizes',
    loadChildren: () =>
      import('./home/prizes/prizes.module').then((m) => m.PrizesPageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./home/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'dictionary',
    loadChildren: () =>
      import('./home/dictionary/dictionary.module').then(
        (m) => m.DictionaryPageModule
      ),
  },
  {
    path: 'learn',
    loadChildren: () =>
      import('./home/learn/learn.module').then((m) => m.LearnPageModule),
  },
  {
    path: 'remote-lessons',
    loadChildren: () =>
      import('./home/remote-lessons/remote-lessons.module').then(
        (m) => m.RemoteLessonsPageModule
      ),
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./test/test.module').then((m) => m.TestPageModule),
  },
  { path: '**', component: ProfileComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
