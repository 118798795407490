import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface UserDetails {
  _id: string;
  email: string;
  name: string;
  role: string;
  status: string;
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
  duplicateUser?: boolean;
}

export interface TokenPayload {
  email: string;
  password: string;
  name?: string;
  role?: string;
  status?: string;
  userData?;
}

@Injectable()
export class AuthenticationService {
  url;
  userBackendMessages = new Subject();
  private token: string;

  constructor(private http: HttpClient, private router: Router) {
    const loc = window.location.hostname;
    if (loc !== 'test.lingking.com.pl' && loc !== 'lingking.com.pl') {
      // this.url = 'http://' + window.location.hostname + ':3000/api/users/';
      // this.url = 'https://lingking.com.pl:3001/api/users/';
      this.url = 'https://backend-int.linget.it/api/users/';
    } else {
      // this.url = 'https://lingking.com.pl:3001/api/users/';
      this.url = 'https://backend-int.linget.it/api/users/';

      // this.fileUrl = this.url +'/api/sentences/'
    }
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('mean-token');
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  public register(user: TokenPayload): Observable<any> {
    // this.request('post', 'register', user).subscribe(result=>{
    //
    //   if(result.duplicateUser === true){
    //     user.email = user.email +'1'
    //
    //     this.register(user)
    //   }
    // })
    return this.request('post', 'register', user);
  }

  public login(user: TokenPayload): Observable<any> {
    return this.request('post', 'login', user);
  }

  public profile(): Observable<any> {
    return this.request('get', 'profile');
  }

  changePassword(oldPassword, newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'changepassword', {
        oldPassword,
        newPassword,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message.newUserId);
      });
  }

  setPassword(newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'setpassword', {
        newPassword,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  changeAppsEmail(newEmail, id) {
    console.log(
      '🚀 ~ file: authentication.service.ts ~ line 169 ~ AuthenticationService ~ changeAppsEmail ~ id',
      id
    );
    this.http
      .post<{ message: any }>(this.url + 'changeEmail', {
        newEmail,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  forgotPassword(email) {
    this.http
      .post<{ message: any }>(this.url + 'forgot', { email })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetPassword(token, newPassword) {
    this.http
      .post<{ message: any }>(this.url + 'reset', {
        token,
        newPassword,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetFreshPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'resetfreshpassword', { id })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetFreshWithTempPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'reset-fresh-with-temp-password', {
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  sendEmail(id) {
    this.http
      .post<{ message: any }>(this.url + 'sendemail', { id })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('mean-token');
    sessionStorage.removeItem('userDetails');
    this.router.navigateByUrl('/');
  }

  userBackendMessagesListener() {
    return this.userBackendMessages.asObservable();
  }

  private saveToken(token: string): void {
    localStorage.setItem('mean-token', token);
    this.token = token;
  }

  private request(
    method: 'post' | 'get',
    type: 'login' | 'register' | 'profile',
    user?: TokenPayload
  ): Observable<any> {
    let base;

    if (method === 'post') {
      base = this.http.post(this.url + type, user);
    } else {
      base = this.http.get(this.url + type, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    }

    const request =
      this.http.post(`https://backend-dev.linget.it/api/login`, user)
    base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }

        return data;
      })
    );

    return request;
  }
}
