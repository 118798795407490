import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user;
  version: '1.0.0';
  constructor() {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.preventPullToRefresh('body'); // Or pass any other selector for the container element
  }
  public hideLoader(): void {
    document.querySelector('#app-loader')?.remove();
  }
  // preventPullToRefresh(element: string) {
  //   // using 'string', not 'HTMLElement'
  //   let prevent = false;

  //   document
  //     .querySelector(element)
  //     ?.addEventListener('touchstart', function (e: TouchEvent) {
  //       if (e.touches.length !== 1) {
  //         return;
  //       }

  //       let scrollY =
  //         window.pageYOffset ||
  //         document.documentElement.scrollTop ||
  //         document.body.scrollTop ||
  //         0;
  //       prevent = scrollY === 0;
  //     });

  //   document
  //     .querySelector(element)
  //     ?.addEventListener('touchmove', function (e: TouchEvent) {
  //       if (prevent) {
  //         prevent = false;
  //         e.preventDefault();
  //       }
  //     });
  // }
}
