import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RtcService } from 'src/app/shared/services/rtc.service';

@Component({
  selector: 'app-progress',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userDetails: Observable<any>;
  version = '1.0.15';
  constructor(
    private router: Router,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    private rtcService: RtcService
  ) {}

  ngOnInit() {
    this.userDetails = this.userDetailsService.userDetailsBearer.asObservable();
    this.userDetailsService.getUserDetails();

    // setTimeout(() => {
    //   const lastResetProgressDate =
    //     this.userDetailsService.userDetails.userData.lastResetProgress;

    //   const currentDate = Date.now();
    //   const numberMilisecondsFromLastReset =
    //     currentDate - lastResetProgressDate;
    //   console.log(
    //     '🚀 ~ file: profile.component.ts:31 ~ ProfileComponent ~ setTimeout ~ numberMilisecondsFromLastReset:',
    //     numberMilisecondsFromLastReset
    //   );

    //   if (
    //     numberMilisecondsFromLastReset > 604800000 ||
    //     !lastResetProgressDate
    //   ) {
    //     this.resetProgress();
    //   }
    // }, 1000);
  }
  resetProgress() {
    this.userDetailsService.resetProgress();
  }
}
